import React,{ useEffect,useState } from "react";
import PullToRefresh from "react-simple-pull-to-refresh";
import QrReader from 'react-qr-reader'
import Fab from '@mui/material/Fab';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import DescriptionIcon from '@mui/icons-material/Description';
import { Global } from '@emotion/react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import UserOptions from './UserOptions'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
    Type as ListType
  } from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';

import {
    ActionContent,
    ItemColumn,
    ItemColumnCentered,
    ItemContent,
    ItemInfoLine,
    ItemNameLine,
    ItemRow
} from './styledComponents';

import DeleteIcon from "./Icons/DeleteIcon";
import { stepClasses, Typography } from "@mui/material";
import { typography } from "@mui/system";

const colors = {
    accepted: '#4a7b2b',
    deleted: '#bc281e',
    rejected: '#ba772b',
    waitlist: '#296690',
};

const VendorList = (props) => {

    const [visitors,setVisitors] = useState([]);
    const [QROpen,setQROpen] = useState(false);
    const [open,setOpen] = useState(false);
    const [settingsOpen,setSettingsOpen] = useState(false);
    const [selectedVisit,setSelectedVisit] = useState({});
    const [deltaNotes,setDeltaNotes] = useState(null)
    const [deltaListItem,setDeltaListItem] = useState(null);
    const [options,setOptions] = useState([])

    //get vendor visits
    useEffect(() => {
        fetchRecords();
        fetchOptions();
    },[])

    const fetchOptions = () => { 
      return fetch('/api/event/440101654897/settings',{cache:"no-store"}).then((response) => { response.json().then(data => { 
        setOptions(data.listSettings);
        })
      })  
    }
    
    const fetchRecords = () => {
      return fetch('/api/event/440101654897/visits',{cache:"no-store"}).then(response => {
        response.json().then(data => { 
          setVisitors(data);
        })
      })
    }

    const stringToColor = (string) => {
      let hash = 0;
      let i;
    
      /* eslint-disable no-bitwise */
      for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
      }
    
      let color = '#';
    
      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
      }
      /* eslint-enable no-bitwise */
    
      return color;
    }

    const stringAvatar = (name) => {
      return {
        sx: {
          bgcolor: stringToColor(name),
          padding:"2px"
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
    
      const handleDelete = id => () => {
        fetch(`/api/event/440101654897/visits/${id}`,{method:"DELETE"})
      };
    
      const handleOnClick = (visit) => {
        fetch(`/api/event/440101654897/attendee/${visit.attendeeId}`).then(response => {
          response.json().then(json => {
            setSelectedVisit({...visit,...json});
            setOpen(true);
          })
        })
      };
    
      const trailingActions = (id) => (
        <TrailingActions>
          <SwipeAction destructive={true} onClick={handleDelete(id)}>
            <ActionContent style={{ backgroundColor: colors.deleted }}>
              <ItemColumnCentered>
                <span className="icon">
                  {
                    <DeleteIcon />
                  }
                </span>
                Delete
              </ItemColumnCentered>
            </ActionContent>
          </SwipeAction>
        </TrailingActions>
      );

    //might need added delay too fast at the moment
    const onRefresh = () => {
      return fetchRecords();
    }

    const handleScan = (scan) => {

      if (scan && QROpen)
      {
        if (Number.isInteger(parseInt(scan)))
        {
          fetch('/api/event/440101654897/visits',{method:"POST",body:JSON.stringify({attendeeId:scan})}).then(fetchRecords)
        }
        setQROpen(false);
        console.log(scan);
      }
      

    }

    const onDrawerOpen = () => {
      //
    }

    const onDrawerClose = () => {
      setQROpen(false);
    }

    const handleSave = (listSettings) => {

      fetch('/api/event/440101654897/settings', {
        method:"PUT",
        body:JSON.stringify({listSettings:listSettings})
      }).then(() => {setSettingsOpen(false); fetchOptions()});

    }

    const handleClose = () => {

      if (deltaNotes !== null || deltaListItem !== null)
      {
        fetch(`/api/event/440101654897/visits/${selectedVisit.visitId}`,{
          method:"PUT",
          body:JSON.stringify({
            notes:deltaNotes,
            type:deltaListItem
          })
        }).then(() => fetchRecords())
      }
      setOpen(false); 
      setDeltaNotes(null)
      setDeltaListItem(null)
      
    }

    console.log(selectedVisit);

    return (
      <div key={"bottom"} style={{backgroundColor:'#393a52'}}>
      <Fab in sx={{position:"fixed",bottom: 32,right: 32,zIndex:50}} onClick={()=>{console.log("clicked");setQROpen(true)}}>
          <QrCodeScannerIcon/>
      </Fab>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            backgroundColor:"#555878",
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          }
        }}
      />
      <SwipeableDrawer
        anchor={"bottom"}
        open={QROpen}
        onClose={onDrawerClose}
        onOpen={()=>{}}
        disableSwipeToOpen={true}
      >
        <QrReader
          delay={100}
          //onError={this.handleError}
          onScan={handleScan}
          style={{ width: '100%'}}
        />
      </SwipeableDrawer>
      <div style={{float:"right", padding:16, paddingRight:32, backgroundColor:'#393a52'}}><Avatar onClick={()=> setSettingsOpen(true)}/>
      </div>
      <PullToRefresh onRefresh={onRefresh} backgroundColor='#636582'>
      <div className="basic-swipeable-list__container" style={{backgroundColor:"#393a52",height:"100vh"}}>
        <SwipeableList
          style={{ backgroundColor: '#393a52' }}
          threshold={.5}
          type={ListType.ANDROID}
        >
          {visitors.map(({ name,visitId,scanTime,notes,attendeeId,type}) => (
            <SwipeableListItem
              key={visitId}
              trailingActions={trailingActions( visitId )}
              //onSwipeProgress={setSwipeProgress}
              onClick={() => handleOnClick({name,visitId,scanTime,notes,attendeeId,type})}
            >
              <ItemContent>
                <ItemRow>
                  <Avatar {...stringAvatar(name)} />
                  <ItemColumn>
                    <ItemNameLine>{name}</ItemNameLine>
                    <ItemInfoLine>
                      {new Date(scanTime).toLocaleTimeString()
                      }{' '}{new Date(scanTime).toLocaleDateString()}
                      <span
                        style={{
                          backgroundColor:   'transparent'
                        }}
                      >
                        
                      </span>
                    </ItemInfoLine>
                  </ItemColumn>
                </ItemRow>
                {notes || type?
                <DescriptionIcon style={{paddingRight:16}}/>
                :""
                }
              </ItemContent>
            </SwipeableListItem>
          ))}
        </SwipeableList>
      </div>
    </PullToRefresh>
    <UserOptions open={settingsOpen} handleClose={() => {setSettingsOpen(false)}} handleSave={handleSave}/>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedVisit.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
            {`
              email: ${selectedVisit.email?selectedVisit.email:""}
            `}
            </Typography>
            <Typography>
            {`
            Phone: ${selectedVisit.phone?selectedVisit.phone:""}
            `}
            </Typography>
          </DialogContentText>
          <br/>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Bike Model</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={deltaListItem?deltaListItem: selectedVisit.type?selectedVisit.type:""}
                label="Bike Model"
                onChange={(event)=> {setDeltaListItem(event.target.value)}}
                displayEmpty={true}
              > 
              {
                [<MenuItem value={null} style={{height:36}} ></MenuItem>,...options.map((option,index) => <MenuItem value={option}>{option}</MenuItem>)]
              }
        </Select>
        <br/>
      </FormControl>
          <TextField
            margin="dense"
            id="notes"
            label="Notes"
            fullWidth
            variant="standard"
            multiline
            onChange={(event) => {setDeltaNotes(event.target.value)}}
            value={deltaNotes !== null ? deltaNotes : (selectedVisit.notes?selectedVisit.notes:"")}
          />
        </DialogContent>
      </Dialog>
    </div>
    )

}

export default VendorList




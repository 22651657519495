import logo from './logo.svg';
import './App.css';
import VendorList from './VendorList';
import Login from './Login';
import React, {useEffect,useState} from 'react';

const App = () => {

  let [loaded,setLoaded] = useState(false);
  let [loggedIn,setLoggedIn] = useState(false);

  useEffect(() => {
    fetch('/api/login',{method:"POST"}).then((response) => {
      if (response.status == 200)
      {
        setLoggedIn(true);
      }
      setLoaded(true);
    })
  },[])

  if (!loaded)
    return ''

  return (
    <div className="App">
      {loggedIn?<VendorList/>:<Login setLoggedIn={(val) => setLoggedIn(val)}/>}
    </div>
  );
}

export default App;

import React, {useEffect,useState} from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ReorderIcon from '@mui/icons-material/Reorder';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListItem } from '@mui/material';

const ConfigList = (props) => {

    const {options} = props;

    const updateItem = (index,value) => {
        options[index] = value
        props.setOptions([...options]);
    }

    const handleDelete = (index) => {
        options.splice(index,1);
        props.setOptions([...options])
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          options,
          result.source.index,
          result.destination.index
        );
    
        props.setOptions(items)
      }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
      };

    return (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              //style={getListStyle(snapshot.isDraggingOver)}
            >
              {options.map((item, index) => (
                <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                        <div style={{display:"flex"}}>
                      <div {...provided.dragHandleProps} style={{padding:16}}><ReorderIcon fontSize="large"/></div>
                        <TextField
                            style={{alignSelf:"center"}}
                            margin="dense"
                            id={index.toString()}
                            fullWidth
                            variant="standard"
                            multiline
                            value={item}
                            onChange={(event) => {updateItem(index.toString(),event.target.value)}}
                            />
                            <div style={{float:"right", padding:16}}><IconButton onClick={() => handleDelete(index)}><DeleteIcon fontSize="large"/></IconButton></div>
                        </div>
                        </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>)

}

const Reorderable = (props) => {

    return 

}

export default ConfigList
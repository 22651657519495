import React,{ useEffect,useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import LogoutIcon from '@mui/icons-material/Logout';
import ConfigList from './ConfigList';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {

    const [newItemVal,setNewItemVal] = useState("")

    const [loading,setLoading] = useState(true);
    const [options,setOptions] = useState([])

    useEffect(()=> {
        
        fetch('/api/event/440101654897/settings',{cache:"no-store"}).then((response) => { response.json().then(data => { 
            console.log(data);
            setOptions(data.listSettings);
            setLoading(false);
            })}).catch(error => {setLoading(false)});
          
    },[props.open])

    const handleChange = (event) => {
        setNewItemVal(event.target.value);
    }

    const handleCreate = (event) => {
        //todo
        options.push(newItemVal);
        setOptions([...options])
        setNewItemVal("");
    }

    const handleLogout = () => {
      fetch('/api/logout',{cache:"no-store"}).then(response => {
        if (response.status == 200)
        {
          window.location.reload();
        }
      })
    }

  return (
    <div style={{ backgroundColor: '#393a52' }}>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Settings
            </Typography>
            <Button autoFocus color="inherit" onClick={() => props.handleSave(options)}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
            {loading?"":
            (<>
            <ConfigList options={options} setOptions={setOptions}/>
            <div style={{padding:16,display:"flex"}} >
            <TextField
            style={{alignSelf:"center"}}
            margin="normal"
            label={"Add New List Item"}
            id={"AddNew"}
            fullWidth
            variant="standard"
            value={newItemVal}
            onChange={handleChange}
            onKeyDown={(e) => {if (e.keyCode == 13)handleCreate()}}
            />
            <Fab onClick={handleCreate} style={{float:"right", paddingRight:"16",alignSelf:"center"}} size="small" color="primary" aria-label="add">
            <AddIcon />
            </Fab>
            </div>
            </>)
        }
        <div style={{display: "block",
  marginLeft: "auto",
  marginRight: "auto",width:"25%"}}><Button fullWidth={false} variant="contained" onClick={handleLogout} startIcon={<LogoutIcon />}>
            Logout
        </Button>
        </div>
        </List>
      </Dialog>
    </div>
  );
}